.container {
    width: 100%;
    margin: 0;
    border: 0;
}

.frame {
    aspect-ratio: 16/9;
    width: 90%;
    max-width: 800px;
}