.main {
  width: 100%;
  height: calc(100% - 60px);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
}

.container {
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
