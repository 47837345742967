.container {
    width: 100%;
}

.inset {
    width: 50%;
    margin: 100px auto;
    text-align: center;
}

.title {
    font-weight: 600;
    font-size: 30pt;
}

.text {
    font-size: 15pt;
}

.image {
    width: 300px;
    margin: 0 auto;
    display: block;
}