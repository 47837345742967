.table {
  border: 1px solid black;
  border-collapse: collapse;
  /* margin: 25px 0; */
  width: 100%;
}

.tableHeader {
  border: 1px solid black;
  background: rgb(79, 79, 238);
  color: white;
  padding: 8px;
  font-weight: bold;
}

.tableData {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 0.5rem;
}

.tableData button {
  margin-left: 5px;
  margin-right: 5px;
}

.table a {
  text-decoration: none;
  color: darkblue;
  font-weight: bold;
}

.table a:hover {
  border-bottom: 1px solid darkblue;
}

.nameLabel {
  font-weight: bold;
}

.checklistName {
  font-style: italic;
}
