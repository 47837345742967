.container {
    width: 100%;
    margin: 20px auto;
    padding-top: 40px;
    padding-bottom: 40px;
}

.examples {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.title {
    text-align: center;
    font-size: 25pt;
    font-weight: 500;
    margin: 0;
}

.example {
    margin: 20px 20px;
}   

.example a {
    text-decoration: none;
}

.example div {
    display: flex;
    justify-content: center;
}

.example p {
    font-weight: 500;
    text-align: center;
}

.example img {
    max-height: 400px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.buttonContainer {
    margin-top: 10px;
}

@media (max-width: 800px) {
    .examples {
        flex-direction: column;
    }

    .example {
        margin: 20px auto;
    }
}