.container {
    height: 100%;
    margin: 0;
    padding: 0;
}

.inset {
    display: flex;
    justify-content: center;
}

.input {
    display: none;
}

.modal {
    text-align: center;
}

.currentLogo {
    width: 70%;
    /* border: 1px solid black; */
    border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto;
}

.logoFilename {
    width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 5px;
}

.deleteIcon {
    display: flex;
    align-items: center;
    padding-right: 5px;
}

.deleteIcon:hover {
    color: red;
}

.modalTitle {
    font-size: 19pt;
    font-weight: 600;
}