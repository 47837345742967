.container {
    width: 100%;
    height: 100%;
}

.content {
    width: 90%;
    max-width: 800px;
    margin: 10px auto;
    font-family: Arial;
}

.inset {
    width: 60%;
    margin: 0 auto;
}

.content p {
    color: rgb(80, 80, 80);
    line-height: 2;
}

.content ul {
    color: rgb(80, 80, 80);
}

.intro {
    font-size: 15pt;
}

.pageLink {
    font-weight: 500;
    color: #4f4fee;
}

.pageLink:hover {
    color: black;
    cursor: pointer;
}

.italic {
    font-style: italic;
    font-weight: 800;
}

.screenshot {
    max-width: 100%;
    max-height: 400px;
    border: 1px solid lightgrey;
    border-radius: 5px;
}

.iconTitle {
    display: flex;
    align-items: center;
}

@media (max-width: 800px) {
    .inset {
        width: 90%;
    }
}