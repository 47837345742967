.container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tutorial {
  text-align: center;
  margin: 20px auto;
}

.video {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}

.title {
  font-size: 40pt;
  font-weight: 600;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}

.subtitle {
  font-size: 20pt;
  color: grey;
  font-style: italic;
  font-weight: 500;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}

.smallerTitle {
  font-size: 25pt;
  font-weight: 500;
  text-align: center;
  padding-right: 15px;
  padding-left: 15px;
}


.perks {
  width: 90%;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.perks ul {
  margin-top: 10px;
  font-size: 20pt;
  list-style: none;
}

.perks li {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: grey;
  margin: 10px 0;
}

.signupButton {
  display: flex;
  justify-content: center;
  text-decoration: none;
}
