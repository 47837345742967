.container {
  height: 100%;
  overflow: auto;
}

.spinner {
  text-align: center;
}

.item {
  width: 90%;
  margin: 10px auto;
  /* border: 1px solid lightgrey; */
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.item p span:first-of-type {
  font-weight: 500;
}

.title {
  text-align: center;
  font-size: 18pt;
  font-weight: 600;
}

.timelineMessage {
  text-align: center;
}
