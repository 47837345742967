.container {
    width: 100%;
    margin-bottom: 50px;
}

.title {
    text-align: center;
    font-size: 25pt;
    font-weight: 500;
}

.logoContainer {
    text-align: center;
    display: block;
    min-width: 250px;
    max-width: 400px;
    margin: 0 auto;
}

.logo {
    width: 100%;
}