.subchecklist {
  margin-bottom: 5px;
  margin-top: 5px;
}

.checkItem {
  margin-bottom: 30px;
}

.condition {
  margin-bottom: 10px;
}

.handle {
  cursor: grabbing;
}

.title {
  /* flex-direction: row-reverse; */
}

.draggedOver {
  color: grey;
}

.controls {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.delete {
  margin-right: 10px;
}
