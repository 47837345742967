.container {
  width: 100%;
}

.leftside {
  width: 50%;
}

.rightside {
  width: 30%;
}

.title {
  text-align: center;
  font-size: 18pt;
  font-weight: 500;
}

.inset {
  width: 60%;
  margin: 0 auto;
}

.subscriptionBox {
  width: 40%;
  min-width: 400px;
  margin: 10px auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subscriptionBox:hover {
  cursor: pointer;
}

.subscriptionBox * {
  display: inline-block;
}

.planPrice {
  font-weight: 500;
}

.controls {
  display: flex;
  justify-content: center;
}

.noteContainer {
  width: 60%;
  margin: 50px auto;
  text-align: center;
}

.note {
  font-style: italic;
  color: rgb(156, 155, 155);
}

.currentSubscriptionNote {
  font-size: 10pt;
  color: rgb(39, 160, 39);
  margin-right: 15px;
}

.currencySelector {
  display: flex;
  justify-content: center;
}

.noCurrentSubscriptionNote {
  color: red;
  font-size: 14pt;
  font-weight: 600;
  text-align: center;
}

@media (max-width: 800px) {
  .inset {
    width: 90%;
  }
  .subscriptionBox {
    width: 90%;
    min-width: 90%;
  }
}
