.summary {
    display: flex;
    align-items: center;
}

.title {
    font-style: italic;
}

.delete {
    margin-bottom: 30px;
}

.handle {
    cursor: grabbing;
}
  