.container {
  height: 100%;
}

.splitpane {
  position: relative !important;
}

.loading {
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
}
