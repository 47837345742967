.container {
    width: 100%;
    height: 100%;
    display: flex;
}

.leftSide {
    width: 50%;
    height: 100%;
}

.rightSide {
    width: 50%;
    height: 100%;
}