.card {
  width: 90%;
  /* max-width: 60rem; */
  /* border: 2px solid lightgrey; */
  margin: 2rem auto;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
}

