.textBox {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .controls {
    display: flex;
    align-items: center;
  }
  
  .delete {
    margin-right: 10px;
  }
  
  .handle {
    cursor: grabbing;
  }
  
  .summary {
    display: flex;
    align-items: center;
  }

  .editor {
      margin-top: 20px;
      list-style: circle;
  }

  .helperText {
    margin: 0;
    color: red;
    font-size: 10pt;
  }
  