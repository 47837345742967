.container {
    margin-right: 15px;
}

.indentSelectLabel {
    font-weight: 500;
    display: block;
}

.indentSelect {
    background-color: transparent;
    border: 1px solid lightgrey;
    border-radius: 3px;
    padding: 0 5px;
    margin: 0;
    width: 100%;
    height: 28px;
    font-family: inherit;
}

.indentSelect:hover {
    border: 1px solid grey;
}

