.frequencyItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.frequencyItemForm {
  margin-right: 10px;
}

.frequencyItemDraggedOver {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  background: lightgrey;
}

.frequencyItemDraggedOver input {
  margin-right: 10px;
}

.handle {
  cursor: grabbing;
  margin-right: 10px;
}

.editor {
  display: flex;
  align-items: center;
}

.controls {
  display: flex;
}

.deleteButton {
  margin-left: 20px;
}
