.inputField label {
    display: block;
    font-weight: 500;
}
  
.inputField input {
    padding: 5px;
    border: 1px solid lightgrey;
    border-radius: 3px;
    font-family: inherit;
    max-width: 150px;
}

.inputField input:hover {
    border: 1px solid grey;
}

.inputField input:focus {
    outline: 1px solid #4f4fee;
}

.error label {
    display: block;
    font-weight: 500;
}
  
.error input {
    padding: 5px;
    border: 1px solid red;
    border-radius: 3px;
    font-family: inherit;
}

.error input:hover {
    border: 1px solid red;
}

.error input:focus {
    outline: 1px solid red;
}

.errorText {
    font-size: 10pt;
    margin: 0;
    color: red;
}
