.title {
  margin-bottom: 20px;
}

.title span {
  font-size: 25px;
  position: absolute;
  visibility: hidden;
  white-space: pre;
  font-family: inherit;
}

.title input {
  font-size: 25px;
  min-width: 130px;
  max-width: 800px;
  border: transparent;
  padding: 0.25em;
  font-family: inherit;
}

.title input:hover {
  border-radius: 3px;
  outline: 2px solid lightgrey;
}

.title input:focus {
  border-radius: 3px;
  outline: 3px solid #4f4fee;
}

.errorTitle {
  margin-bottom: 20px;
}

.errorTitle span {
  font-size: 25px;
  position: absolute;
  visibility: hidden;
  white-space: pre;
  font-family: inherit;
}

.errorTitle input {
  font-size: 25px;
  min-width: 130px;
  max-width: 800px;
  border: 2px solid red;
  border-radius: 3px;
  padding: 0.25em;
  font-family: inherit;
}

.errorTitle input:focus {
  border-radius: 3px;
  /* border: hidden; */
  outline: 2px solid red;
}

.helperText {
  color: red;
  margin: 0;
  font-size: 10pt;
}
