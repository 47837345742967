.container {
    width: 100%;
    height: 100%;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.leftSide {
    width: 30%;
    padding: 20px;
    overflow: auto;
}

.mainInfo span:first-child {
    display: block;
    font-weight: 500;
}

.mainInfo span:last-child {
    display: block;
    color: grey;
}

.rightSide {
    width: 70%;
    overflow: auto;
}

.title {
    font-size: 20pt;
    font-weight: 500;
}

.miscInfo {
    font-size: 10pt;
}

.miscInfo span {
    display: inline;
}

.miscInfo span:last-child {
    font-weight: 300;
}

.divider {
    height: 1px;
    border: none;
    background: lightgrey;
    width: 100%;
}

.controls button:first-child {
    margin-right: 5px;
}

@media (max-width: 800px) {
    .content {
        flex-direction: column;
    }

    .leftSide {
        width: 100%;
    }

    .rightSide {
        width: 100%;
    }
}