.header {
  /* position: fixed; */
  width: 100%;
  height: 60px;
  display: flex;
  padding: 0 10%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background-color: #4f4fee;
  z-index: 1;
}

.logo {
  font-size: 25pt;
  font-family: 'Fugaz One', cursive;
  color: white;
  cursor: pointer;
}

.nav ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav li {
  margin-left: 1.5rem;
  font-size: 1.25rem;
}

.nav a {
  text-decoration: none;
  color: white;
}

.nav a:hover,
.nav a:active,
.nav a:active {
  color: white;
  border-bottom: 2px solid white;
}

.testmarker {
  color: #69f0ae;
  /* font-family: 'Fugaz One', cursive; */
  font-family: Arial;
  vertical-align: sub;
  font-size: medium;
  font-weight: 600;
}

@media (max-width: 800px) {
  .logo {
    font-size: 20pt;
  }
}

@media (max-width: 450px) {
  .logo {
    font-size: 15pt;
  }
}
