.editor {
  height: 100%;
  padding: 10px 10px;
  box-sizing: border-box;
}

.container {
  height: 100%;
  width: 100%;
  min-width: 695px; /* To make sure the controls toolbar doesnt collapse when the window is resized smaller. */
}

.subchecklistContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}

.controls {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selections {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.saveButton {
  height: 100%;
}

.saveButton button {
  margin: 0 5px;
}

.columnControl {
  margin-right: 10px;
  width: 100%;
}

.noItemsText {
  font-size: 15pt;
  font-weight: 400;
  margin-left: 10px;
  color: grey;
}

.timesNewRoman {
  font-family: "Tinos", serif;
}

.arial {
  font-family: "Arimo", sans-serif;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.calibri {
  font-family: "Carlito", sans-serif;
}

.monospace {
  font-family: "Space Mono", monospace;
}

.centered {
  text-align: center;
}
