.condition {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  border: 2px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
}

.condition input {
  margin-right: 10px;
}

.conditionDraggedOver {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  border: 2px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  background: lightgrey;
}

.conditionDraggedOver input {
  margin-right: 10px;
}

.handle {
  cursor: grabbing;
  margin-right: 10px;
}

.editor {
  display: flex;
  align-items: center;
}

.controls {
  display: flex;
}
