.buttonOuter {
    width: 64px;
    height: 32px;
    border-radius: 5px;
    border: 1px solid grey;
    background: rgb(233, 233, 233);
    display: flex;
    align-items: center;
}

.buttonOuter:hover {
    cursor: pointer;
    background: rgb(178, 178, 178)
}

.buttonOuter:active {
    cursor: pointer;
    background: rgb(141, 140, 140)
}

.buttonInner {
    width: 51px;
    height: 21px;
    border-radius: 1px;
    margin: 0 auto;
    border: 1px solid grey;
}

.colorInput {
    margin: 0 auto;
}

.hexInput {
    display: block;
    box-sizing: border-box;
    width: 90px;
    margin: 15px auto;
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #eee;
    outline: none;
    font: inherit;
    text-transform: uppercase;
    text-align: center;
}

.colorBar {
    height: 20px;
    width: 150px;
    margin: 0 auto;
    margin-bottom: 15px;
    border: 2px solid lightgrey;
    border-radius: 5px;
}
