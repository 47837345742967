.container {
  width: 100%;
  margin: 0px;
  padding: 0;
  /* background: lightblue; */
  /* border-radius: 10px; */
}

.title {
  margin-top: 30px;
  font-size: 30pt;
  font-weight: 600;
  text-align: center;
  padding: 20px;
}

.subtitle {
  font-size: 25pt;
  font-weight: 600;
  text-align: center;
}

.plans {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan {
  width: 25%;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: white;
  box-sizing: border-box;
}

.planTitle {
  text-align: center;
  font-size: 20pt;
  font-weight: 500;
}

.price {
  text-align: center;
  font-size: 20pt;
  font-weight: 500;
}

.currencySelector {
  text-align: center;
  margin: 20px auto;
}

.pages {
  display: flex;
  justify-content: center;
  align-items: center;
}

.page {
  width: 25%;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: white;
  box-sizing: border-box;
}

.description {
  text-align: center;
  font-weight: 500;
  color: grey;
}

.plans ul, .pages ul {
  list-style: none;
}

.plans li, .pages li {
  display: flex;
  align-items: center;
}

.subscriptionsDisabled {
  text-align: center;
  color: red;
}

@media (max-width: 800px) {
  .plans, .pages {
    flex-direction: column;
  }

  .plans div {
    width: 80%;
  }

  .page {
    width: 80%;
  }
}
