
.content {
    width: 90%;
    margin: 0 auto;
}

.title {
    text-align: center;
}

.backlink {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15pt;
    position: absolute;
    left: 0;
}

.backlink a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #4f4fee;
}

.ordersTable th {
    background: #4f4fee;
    color: white;
}

.topRow {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    justify-content: center;
}

.pageNumber {
    display: flex;
    justify-content: center;
    padding: 8px;
  }

.searchBar {
    margin-bottom: 10px;
}