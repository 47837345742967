.container {
    height: 100%;
    width: 100%;
    display: flex;
}

.pdfContainer {
    width: 75%;
    margin: 0 auto;
    overflow: auto;
}

.controls {
    width: 25%;
    padding: 20px;
    /* border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 20px; */
}

.title {
    font-size: 20pt;
    font-weight: 600;
}

.text {
    font-size: 15pt;
    font-weight: 400;
}

.pageCount {
    font-size: 15pt;
    margin-top: 50px;
    /* text-align: center; */
    /* color: lightgreen; */
}

.bold {
    font-weight: 500;
}

.ordersDisabledNotice {
    color: red;
}