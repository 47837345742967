.container {
  text-align: center;
}

.container span {
  font-weight: 600;
}

.container div {
  margin: 10px;
}

.billingLink {
  font-weight: 500;
  color: #4f4fee;
}

.billingLink:hover {
  color: black;
  cursor: pointer;
}

.subscriptionLink {
  font-weight: 500;
  color: #4f4fee;
}

.subscriptionLink:hover {
  color: black;
  cursor: pointer;
}
