.title span {
  position: absolute;
  visibility: hidden;
  font-size: 1em;
  font-family: inherit;
  white-space: pre;
}

.title input {
  min-width: 85px;
  max-width: 600px;
  font-size: 1em;
  border: transparent;
  padding: 0.25em;
  font-family: inherit;
  background: inherit;
}

.title input:hover {
  border-radius: 3px;
  outline: 1px solid lightgrey;
}

.title input:focus {
  border-radius: 3px;
  background: white;
  outline: 2px solid #4f4fee;
}

.errorTitle span {
  position: absolute;
  visibility: hidden;
  font-size: 1em;
  font-family: inherit;
  white-space: pre;
}

.errorTitle input {
  min-width: 85px;
  max-width: 600px;
  font-size: 1em;
  border: 1px solid red;
  border-radius: 3px;
  padding: 0.25em;
  font-family: inherit;
  background: inherit;
}

.errorTitle input:focus {
  border-radius: 3px;
  background: white;
  outline: 2px solid red;
  border: hidden;
}

.helperText {
  color: red;
  margin: 0;
  font-size: 10pt;
}
