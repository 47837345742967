.container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.errorText {
    margin: 0 auto;
    font-weight: 500;
    font-size: 15pt;
}