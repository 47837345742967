.summary {
  display: flex;
  align-items: center;
}

.title {
  font-style: italic;
}

.delete {
  margin-bottom: 30px;
}

.handle {
  cursor: grabbing;
}

.blankSpaceForm * {
  display: block;
  margin: 5px;
}

.blankSpaceForm label {
  font-weight: 500;
}

.blankSpaceForm input {
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 3px;
}

.blankSpaceForm input:hover {
  border: 1px solid grey;
}

.blankSpaceForm input:focus {
  outline: 1px solid #4f4fee;
}

.errorBlankSpaceForm * {
  display: block;
  margin: 5px;
}

.errorBlankSpaceForm label {
  font-weight: 500;
}

.errorBlankSpaceForm input {
  padding: 5px;
  border: 1px solid red;
  border-radius: 3px;
}

.errorBlankSpaceForm input:hover {
  border: 1px solid red;
}

.errorBlankSpaceForm input:focus {
  outline: 1px solid red;
}

.helperText {
  font-size: 10pt;
}

.errorHelperText {
  font-size: 10pt;
  color: red;
}
