.login-form {
  margin: 0 auto;
  width: 90%;
}

.login-form Button {
  margin: 0.5rem auto;
}

.login-form h1 {
  text-align: center;
}

.login-form input {
  width: 100%;
}

.inset {
  width: 40%;
  margin: 0 auto;
}

.create-account {
  margin: 30px auto;
  text-align: center;
}

.create-account label {
  display: block;
}

.login-form a {
  color: #4f4fee;
  text-decoration: none;
  font-weight: 500;
}

.login-form a:hover {
  text-decoration: underline;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loginDisabled {
  text-align: center;
  color: red;
}


@media (max-width: 800px) {
  .inset {
    width: 90%;
  }
}
