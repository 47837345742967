.container {
    width: 100%;
    /* position: absolute; */
    left: 0;
    bottom: 0;
    right: 0;
    /* background: lightgrey; */
}

.topHR {
    border: none;
    width: 80%;
    height: 1px;
    background: lightgrey;
}

.content {
    width: 90%;
    margin: 20px auto;
    /* display: flex;
    flex-direction: row;
    justify-content: space-between; */
}

.columns {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.column {
    margin: 0 50px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.column a {
    color: grey;
    text-decoration: none;
}

.column a:hover {
    color: black;
}

.column p {
    color: grey;
}

.copyright {
    color: grey;
    text-align: center;
}

.iconItem {
    display: flex;
    align-items: center;
}