.checkItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.checkItem input {
  margin-right: 10px;
}

.checkItemDraggedOver {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 15px;
  border: 1px solid lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
  background: lightgrey;
}

.checkItemDraggedOver input {
  margin-right: 10px;
}

.handle {
  cursor: grabbing;
  margin-right: 10px;
}

.editor {
  display: flex;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkItemForm label {
  display: block;
  font-weight: 500;
}

.checkItemForm input {
  padding: 5px;
  border: 1px solid lightgrey;
  border-radius: 3px;
  font-family: inherit;
}

.checkItemForm input:hover {
  border: 1px solid grey;
}

.checkItemForm input:focus {
  outline: 1px solid #4f4fee;
}

.controls {
  display: flex;
}

.deleteButton {
  margin-left: 20px;
}