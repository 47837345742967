.details {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.payments {
    margin-bottom: 50px;
}

.payments h2, .payments p, .checklists h2, .checklists p {
    width: 90%;
    margin: 20px auto;
    /* text-align: center; */
}

.dataTable {
    min-width: 400px;
}

.paymentsTable, .checklistsTable, .ordersTable {
    min-width: 650px;
}

.bold {
    font-weight: 600;
}

.nopayments {
    text-align: center;
}

@media (max-width: 800px) {
    .details {
        width: 90%;
    }
  }