.container {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.document {
  height: 100%;
  width: 100%;
}

.page {
}

.page canvas {
  margin: 20px auto;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  box-sizing: border-box;
}

.page div {
  overflow: hidden;
}

.zoomControls {
  position: fixed;
  z-index: 1;
  opacity: 0.5;
}
.zoomControls button {
  margin-left: 5px;
  margin-top: 5px;
  box-sizing: border-box;
}

.zoomControls button * {
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoomControlsHidden {
  position: fixed;
  z-index: 1;
  opacity: 0;
}
.zoomControlsHidden button {
  margin-left: 5px;
  margin-top: 5px;
  box-sizing: border-box;
}

.zoomControlsHidden button * {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  height: calc(100vh - 90px);
  display: flex;
  justify-content: center;
  align-items: center;
}
