.container {
  width: 90%;
  margin: 0 auto;
}

.checklists li {
  list-style: none;
  justify-content: space-between;
}

.checklists a {
  text-decoration: none;
  color: black;
}
