.form {
  width: 90%;
  margin: 0 auto;
}

.form h1 {
  text-align: center;
}

.form input {
  width: 100%;
}

.form label {
  display: block;
}

.form Button {
  margin: 0.5rem auto;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inset {
  width: 40%;
  margin: 0 auto;
}

@media (max-width: 800px) {
  .inset {
    width: 90%;
  }
}
