.sectionTitle {
  margin-top: 5px;
  margin-bottom: 5px;
}

.controls {
  display: flex;
  align-items: center;
}

.delete {
  margin-right: 10px;
}

.handle {
  cursor: grabbing;
}

.summary {
  display: flex;
  align-items: center;
}
