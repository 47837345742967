.container {
  width: 90%;
  margin: 0 auto;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
