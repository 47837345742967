.password-reset-form {
  margin: 0 auto;
  width: 90%;
}

.password-reset-form Button {
  margin: 0.5rem auto;
}

.password-reset-form input {
  width: 100%;
}

.password-reset-form h1 {
  text-align: center;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inset {
  width: 40%;
  margin: 0 auto;
}


.passwordDisabled {
  text-align: center;
  color: red;
}

@media (max-width: 800px) {
  .inset {
    width: 90%;
  }
}
